
import { ControlConfig, QCReview } from '@ht-vue/quality-control'
import { MaybeAimsJobNumber, splitAimsJobNumber } from '@ht-lib/accounts-common'
import { height } from '../router/routes'
import Vue from 'vue'

export default Vue.extend({
  name: 'Images',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { QCReview },
  props: {
    maybeAimsJobNumber: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      qcConfig: {
        finish: {},
        save: {
          route: '/',
          cb: () => {
            console.log('QC Saved for job:', this.maybeAimsJobNumber)
          }
        },
        inLab: true,
        showFinishSave: false,
        showSave: true,
        showPrintWorksheet: true,
        height
      } as ControlConfig,

      loaded: false,
      doLoad: true,
      showJobInfo: false,
      aimsJobNumber: null as null | MaybeAimsJobNumber
    }
  },
  created (): void {
    this.aimsJobNumber = splitAimsJobNumber(this.maybeAimsJobNumber)
  }
})
